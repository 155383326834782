import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import './ProductFinder.scss';

const ProductFinder: FC<HomePageTypes.TProductFinderProps> = ({
  subTitle,
  title,
  image,
  imageAlt,
  description,
  link,
}) => (
  <div className="product-finder" data-testid="product-finder">
    <Container fluid>
      <div className="product-finder__wrap">
        {image?.fallbackUrl ? (
          <div className="product-finder__image">
            {image?.fallbackUrl ? (
              <GatsbyImage fluid={image} objectFit="contain" alt={imageAlt} />
            ) : null}
          </div>
        ) : null}
        <div className="product-finder__text">
          {subTitle ? <strong className="product-finder__sub-title">{subTitle}</strong> : null}
          <DangerouslySetInnerHtml className="product-finder__title" element="h2" html={title} />
          {description ? (
            <DangerouslySetInnerHtml className="product-finder__description" html={description} />
          ) : null}
          {link?.length ? (
            <Button to={link[0].url} variant="secondary">
              {link[0].name}
              <IconCustom icon="chevron-right" />
            </Button>
          ) : null}
        </div>
      </div>
    </Container>
  </div>
);

export default ProductFinder;
