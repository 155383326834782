import GatsbyImage from 'components/common/GatsbyImage';
import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import './BootsBanner.scss';

const BootsBanner: FC<HomePageTypes.TBootsBannerProps> = ({
  labelText,
  title,
  button,
  imageBtn,
  imageBtnAlt,
  imageBg,
  imageBgAlt,
  image,
  imageMobile,
  imageAlt,
}) => {
  return (
    <div className="boots-section">
      <div className="boots-section__background">
        <GatsbyImage
          isLazyLoading={false}
          fluid={imageBg}
          alt={imageBgAlt}
          objectPosition="right"
        />
      </div>

      <div className="boots-section__content">
        <DangerouslySetInnerHtml html={labelText} className="boots-section__label" />
        <DangerouslySetInnerHtml html={title} className="boots-section__title" />
        <div className="boots-section__button">
          {button && button?.length ? (
            <Button ariaLabel={button[0].name} variant="primary" type="button">
              {button[0].name}
              <IconCustom icon="chevron-right" />
              <GatsbyImage
                isLazyLoading={false}
                fluid={imageBtn}
                alt={imageBtnAlt}
                objectPosition="right"
              />
            </Button>
          ) : null}
        </div>
      </div>
      <div className="boots-section__image">
        <GatsbyImage
          isLazyLoading={false}
          fluid={image}
          alt={imageAlt}
          objectPosition="50% 100%"
          className="desktop"
        />
        <GatsbyImage
          isLazyLoading={false}
          fluid={imageMobile}
          alt={imageAlt}
          objectPosition="50% 100%"
          className="mobile"
        />
      </div>
    </div>
  );
};

export default BootsBanner;
